<template>

</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem,VBToggle } from 'bootstrap-vue'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BFormInvalidFeedback,BRow,BCol
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'


/* eslint-disable global-require */

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import router from '@/router'
import { quillEditor } from 'vue-quill-editor'
export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BFormInvalidFeedback,
    BRow,BCol,
    quillEditor

  },
  props: {
    
  },
  directives: {
   
     'b-toggle': VBToggle,
      Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const taskFilters = [
      { title: 'My Task', icon: 'MailIcon', route: { name: 'apps-todo' } },
      { title: 'Important', icon: 'StarIcon', route: { name: 'apps-todo-filter', params: { filter: 'important' } } },
      { title: 'Completed', icon: 'CheckIcon', route: { name: 'apps-todo-filter', params: { filter: 'completed' } } },
      { title: 'Deleted', icon: 'TrashIcon', route: { name: 'apps-todo-filter', params: { filter: 'deleted' } } },
    ]

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
      
    }
  },
  methods:{
      handleSubmit(){

      },
      hide(){
         //$('.b-sidebar-right').hide();
         //$.emit('update:is-sidebar-right-active', false)
          // this.$refs.sidebar-right.hide();
          //sidebar-right

      }
  }
}
</script>

<style>

</style>

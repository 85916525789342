<template>
<div>
    <validation-observer ref="simpleRules">
        <b-form>
            <b-row>
                <b-col md="6">
                    <b-card-actions action-collapse title="Basic Info"> 
                        <b-row>
                            <b-col md="6">
                                 <label>First Name</label>
                                 <b-form-group>
                                    {{ input.firstname }}
                                 </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>Last Name</label>
                                <b-form-group>
                                    {{ input.lastname }}
                                 </b-form-group>
                            </b-col>
                            <b-col md="6">
                                 <label>Email</label>
                                 <b-form-group>
                                    {{ input.email }}
                                 </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>Phone</label>
                                <b-form-group>
                                    {{ input.phone }}
                                 </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-button
                                    variant="primary"
                                   :to="{ name: 'owners-edit', params: { id: input.user_id } }"
                                >
                                    Edit User
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-actions>
                    <form-comment v-if="id" :id="id" />
                </b-col>
                <b-col md="6">
                    <b-card-actions action-collapse title="Enquiry Requirement"> 
                        <b-row>
                            <b-col md="12">
                                <label>Title</label>
                                <b-form-group>
                                    <b-form-input
                                        id="title"
                                        v-model="input.title"
                                        placeholder="Title"
                                    
                                    
                                    />
                                </b-form-group>
                            
                            </b-col>
                            <b-col md="12">
                                <label>Description</label>
                                <b-form-group>
                                <b-form-textarea
                                    id="description"
                                    v-model="input.description"
                                    placeholder="Description"
                                    rows="3"
                                
                                />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>Looking For</label>
                                <b-form-group>
                                
                                    <v-select
                                        v-model="input.looking"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="title"
                                    
                                        :options="option_looking"
                                    />
                                
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>For</label>
                                <b-form-group>
                                
                                    <v-select
                                        v-model="input.fors"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="title"
                                    
                                        :options="option_fors"
                                    />
                                
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>Source</label>
                                <b-form-group>
                                
                                    <v-select
                                        v-model="input.enquiry_source"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        label="title"
                                    
                                        :options="option_sources"
                                    />
                                
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <label>Bedroom</label>
                                <b-form-group>
                                <b-form-spinbutton
                                    v-model="input.bedroom"
                                
                                    size="md"
                                    class="ml-0"
                                    inline
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <label>Bathroom</label>
                                <b-form-group>
                                <b-form-spinbutton
                                    v-model="input.bathroom"
                                
                                    size="md"
                                    class="ml-0"
                                    inline
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>Min - Buy Budget</label>
                                <b-form-group>
                                    <b-form-input
                                            v-model="input.buy_min"
                                        
                                            placeholder="0"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>Max - Buy Budget</label>
                                <b-form-group>
                                    <b-form-input
                                            v-model="input.buy_max"
                                        
                                            placeholder="0"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>MIN - Rental Budget</label>
                                <b-form-group>
                                    <b-form-input
                                            v-model="input.rent_min"
                                        
                                            placeholder="0"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <label>MAX - Rental Budget</label>
                                <b-form-group>
                                    <b-form-input
                                            v-model="input.rent_max"
                                        
                                            placeholder="0"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group>
                                    <label>Zone</label>
                                    <v-select
                                    v-model="input.zone_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="name"
                                    :options="zone"
                            />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group>
                                    <label>BTS Interest</label>
                                    <v-select
                                    v-model="input.bts_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="name"
                                    :options="bts"
                            />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group>
                                    <label>MRT Interest</label>
                                    <v-select
                                    v-model="input.mrt_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="name"
                                    :options="mrt"
                            />
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group>
                                    <label>Airport Link Interest</label>
                                    <v-select
                                    v-model="input.airport_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="name"
                                    :options="airport"
                            />
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
            
                                <b-form-group>
                                <label>Property Pin Interest</label>
                                <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="place_id"
                                >
                                    <vue-autosuggest
                                    ref="autocomplete"
                                    name="place_id"
                                    v-model="place_id"
                                    :suggestions="suggestions"
                                    :input-props="inputProps"
                                    :section-configs="sectionConfigs"
                                    :render-suggestion="renderSuggestion"
                                    :get-suggestion-value="getSuggestionValue"
                                    @input="fetchResults"
                                    />    
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-button
                                    variant="primary"
                                    type="submit"
                                    @click.prevent="validationForm"
                                >
                                    Submit
                                </b-button>
                            </b-col>
                        </b-row> 
                    </b-card-actions>
                    
                </b-col>
                <b-col md="12">
            

                    <b-card-actions action-collapse title="Property Selected">  
                          <b-row class="mb-1">
                            <b-col md="12">
                                 <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                
                                @click="formmail_show"
                                
                                variant="outline-primary"
                                >
                                Generator Email
                                </b-button>
                            </b-col>
                        </b-row>
                        <div class="table-selected table-responsive">
                            <table class="table b-table table-striped table-hover">
                                <thead>
                                    <th></th>
                                    <th></th>
                                   
                                    <th>IMAGE</th>
                                    <th>PROPERTY CODE</th>
                                    <th>Owner</th>
                                    <th>UNITS</th>
                                    <th>FLOOR</th>
                                    <th>TYPE</th>
                                    <th>Close Deal</th>
                                    <th>BUILDING</th>
                                    <th>BD</th>
                                    <th>SQM</th>
                                    <th>RENT</th>
                                    <th>SALE</th>
                                    <th>EXP/AVAIL DATE</th>
                                    <th>RATING</th>
                                    <th>FURNITURE</th>
                                    <th>BTS</th>
                                    <th>MRT</th>
                                    <th>ZONE</th>
                                </thead>
                                <draggable v-model="properties_selected" tag="tbody"  handle=".handle">
                                <tr v-for="item in properties_selected" :key="item.name">
                                    <td scope="row" style="padding-left:10px;"> 
                                        
                                      <feather-icon @click="remove_property(item.id)" icon="MinusIcon" />
                                    </td>
                                    <td> <feather-icon   class="handle size-25  mr-3" style="font-size:20px;" icon="MenuIcon" />
                                    </td>
                                   
                                    <td>
                                        <b-img   class="img_property" v-if="item.property_images[0] "
                                                :src="item.property_images[0].fullfilepath"
                                                
                                            />
                                        <b-img   class="img_property" v-else
                                                :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                                                
                                            />

                                    </td>
                                    <td>
                                        <div class="list-property">
                            
                                            <router-link :to="{ name: 'property-edit', params: { id: item.id } }" >{{ item.property_code }}</router-link>
                                            <b-badge v-if="item.action_id == 1" variant="light-secondary">
                                                {{ item.action_name }}
                                            </b-badge>
                                            <b-badge v-if="item.action_id == 2" variant="light-info">
                                                {{ item.action_name }}
                                            </b-badge>
                                            <b-badge v-if="item.action_id == 3" variant="light-success">
                                                {{ item.action_name }}
                                            </b-badge>
                                            <b-badge v-if="item.action_id == 4" variant="light-warning">
                                                {{ item.action_name }}
                                            </b-badge>
                                            <b-badge v-if="item.status_id == 1" variant="light-success">
                                                {{ item.status_code }}
                                            </b-badge>
                                            <b-badge v-else variant="light-secondary">
                                                {{ item.status_code }}
                                            </b-badge>
                                            <b-badge  variant="light-primary"   @click="doCopy(item.place_name,item.property_code)">
                                                <feather-icon
                                                    icon="CopyIcon"
                                                    size="24"
                                                />
                                                
                                            </b-badge>
                                            <br />
                                            {{ item.place_name }}<br /><br />
                                          
                                        </div>
                                    </td>
                                    <td>
                                
                                        <span class="vs-label"><router-link :to="{ name: 'clients-edit', params: { id: item.owner_id } }"  target="_blank">{{ item.owner_firstname  }} {{ item.owner_lastname  }}</router-link></span><br />
                                        <strong>Email : </strong> <a :href="'mailto:'+item.owner_email">{{ item.owner_email  }} </a><br />
                                        <strong>Phone : </strong><a :href="'tel:'+item.owner_phone">{{ item.owner_phone  }} </a>

                                    </td>
                                    <td>
                                        {{ item.units }} 
                                        {{ item.address_no }}
                                    </td>
                                    <td>{{ item.floors }}</td>
                                    <td>{{ item.type_name }}</td>
                                    <td>
                                        <b-button variant="flat-primary" class="pb0" :to="{ name: 'close-deal-create', params: { id: id, property_id:item.id } }">
                                            close
                                        </b-button>
                                    </td>
                                    <td>{{ item.buildiing }}</td>
                                    <td>{{ item.bedroom }}</td>
                                    <td>{{ item.sqm }}</td>
                                    <td> {{ item.rent_price | getcomma }}</td>
                                    <td>{{ item.sale_price | getcomma }}</td>
                                    <td>{{ item.exp }}</td>
                                    <td>{{ item.rating }}</td>
                                    <td>{{ item.furniture_name }}</td>
                                    <td>{{ item.bts_name }}</td>
                                    <td>{{ item.mrt_name }}</td>
                                    <td>{{ item.zone_name }}</td>
                                </tr>
                                
                                </draggable>
                            </table>
                        </div>
                        
                        
                          
                    </b-card-actions>
                </b-col>
                <b-col md="12">
                    
                    <b-card-actions action-collapse title="Property List">  
                        <div class="mt-1 mb-1">
                    <b-form-checkbox
                        v-model="statusId"
                        value="1"
                        @input="change_status"
                        class="custom-control-primary mr-2"
                    >
                        Avl
                    </b-form-checkbox>
                   </div>
                      
                        <b-table
                            striped
                            hover
                            responsive
                            
                            :items="properties"
                            :fields="fields_properties"
                            show-empty
                            empty-text="No matching records found"
                            
                            
                            
                        >
                        <template slot="top-row" slot-scope="{ fields }">
                            <td v-for="field in fields" :key="field.key">
                                <b-form-input
                                v-if="field.key == 'units'"
                                    v-model="unit"
                                    :placeholder="field.label"
                                    />
                                <b-form-input
                                v-if="field.key == 'floors'"
                                    v-model="floor"
                                    :placeholder="field.label"
                                    />
                                <b-form-input
                                v-if="field.key == 'types'"
                                    v-model="types"
                                    :placeholder="field.label"
                                    />
                                    <b-form-input
                                v-if="field.key == 'buildiing'"
                                    v-model="building"
                                    :placeholder="field.label"
                                    />
                                    <b-form-input
                                v-if="field.key == 'bedroom'"
                                    v-model="bedroom"
                                    :placeholder="field.label"
                                    />
                                    <b-form-input
                                v-if="field.key == 'sqm'"
                                    v-model="sqm"
                                    :placeholder="field.label"
                                    />
                                    <b-form-input
                                v-if="field.key == 'price_rent'"
                                    v-model="price_rent"
                                    :placeholder="field.label"
                                    />
                                    <b-form-input
                                v-if="field.key == 'price_sale'"
                                    v-model="price_sale"
                                    :placeholder="field.label"
                                    />
                                
                             <b-form-input
                    v-if="field.key == 'property'"
                        v-model="propertyfilter"
                        :placeholder="field.label"
                        />
                        <b-form-input
                    v-if="field.key == 'owner'"
                        v-model="ownerfilter"
                        :placeholder="field.label"
                        />
                            </td>
                            </template>
                        <template #cell(id)="data">
                            <feather-icon @click="add_property(data.item)" icon="PlusIcon" />
                        </template>
                        <template #cell(units)="data">
        {{ data.item.units }} 
        {{ data.item.address_no }}
      </template>
                        <template #cell(property)="data">
                            <div class="list-property">
                            <router-link :to="{ name: 'property-edit', params: { id: data.item.id } }" >{{ data.item.property_code }}</router-link>
                            <b-badge v-if="data.item.action_id == 1" variant="light-secondary">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 2" variant="light-info">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 3" variant="light-success">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 4" variant="light-warning">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.status_id == 1" variant="light-success">
                                {{ data.item.status_code }}
                            </b-badge>
                            <b-badge v-else variant="light-secondary">
                                {{ data.item.status_code }}
                            </b-badge>

                            <br />
                            {{ data.item.place_name }}
                            <br /><br />
                            {{ data.item.comment }}
                            </div>
                            
                            
                        </template>
                        <template #cell(image)="data">
                                <b-button variant="flat-primary" class="pb0" :to="{ name: 'property-upload', params: { id: data.item.id } }">
                                <b-img   class="img_property" v-if="data.item.property_images[0]"
                                            :src="data.item.property_images[0].fullfilepath"
                                            
                                        />
                                <b-img   class="img_property" v-else
                                            :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                                            
                                        />
                                </b-button>
                                
                            </template>
                        
                             <template #cell(owner)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        >
        <span class="vs-label">{{ row.item.owner_firstname  }} {{ row.item.owner_lastname  }}</span><br />
         
        </b-form-checkbox>
      </template>
      
       <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Full Name : </strong><router-link :to="{ name: 'clients-edit', params: { id: row.item.owner_id } }"  target="_blank">{{ row.item.owner_firstname  }} {{ row.item.owner_lastname  }}</router-link>
            </b-col>
           
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Email : </strong> <a :href="'mailto:'+row.item.owner_email">{{ row.item.owner_email  }} </a>
            </b-col>
            <b-col
              md="4"
              class="mb-1"
            >
              <strong>Phone : </strong><a :href="'tel:'+row.item.owner_phone">{{ row.item.owner_phone  }} </a>
            </b-col>
            
          </b-row>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Hide Details
          </b-button>
        </b-card>
      </template>
                            <template #cell(price_rent)="data">
                                {{ data.item.rent_price | getcomma }}
                            
                            </template>
                            <template #cell(price_sale)="data">
                                {{ data.item.sale_price | getcomma }}
                                
                            </template>
                        </b-table>
                        

                        
                            <b-pagination
                            v-if="totalpRows > 0"
                            v-model="currentpPage"
                            :total-rows="totalpRows"
                            :per-page="perPage"
                            align="center"
                            size="sm"
                            class="my-0"
                        />
                    </b-card-actions>
                </b-col>
            </b-row>
        </b-form>
        

    </validation-observer>
        <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        no-header
        right
        backdrop
        shadow
          v-model="isSidebarMailOpen"
       class="side-bar-responsive"
        >
         <template >
             <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          
          <h5
            
            class="mb-0"
          >
           Send Mail
          </h5>
          <div>
          
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="formmail_close"
            />
          </div>
        </div>
            <div v-if="isSidebarMailOpen">
            <validation-observer
                #default="{ handleSubmit }"
                ref="refFormObserver"
                >
            <b-form
                    class="p-2"
                    @submit.prevent="handleSubmit(onSubmit)"
                    @reset.prevent="resetForm"
                >
            <b-row>
                <b-col sm="12">
                <validation-provider
                    #default="validationContext"
                    name="Header-Mail"
                    rules="required"
                    >
                    <b-form-group
                        label="Header Mail"
                        label-for="Header-Mail"
                    >
                        <b-form-input
                        id="Header-Mail"
                        v-model="mail.subject"
                        placeholder="Property Listing for you"
                        />

                        <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col sm="6">
                <validation-provider
                    #default="validationContext"
                    name="MailTo"
                    rules="required"
                    >
                    <b-form-group
                        label="Mail To"
                        label-for="MailTo"
                    >
                        <b-form-input
                        id="MailTo"
                        v-model="mail.mailto"
                        placeholder="Mail To"
                        />

                        <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col sm="6">
                
                    <b-form-group
                        label="CC Mail"
                        label-for="ccMail"
                    >
                        <b-form-input
                        id="ccMail"
                        v-model="mail.mailcc"
                        placeholder="CC Mail"
                        />

                    
                    </b-form-group>
                    
                </b-col>
                <b-col sm="12" class="q-description">
                <validation-provider
                    #default="validationContext"
                    name="message"
                    rules="required"
                    >
                            <b-form-group>
                            <label>Message</label>
                            <quill-editor
                            style="height: 150px"
                        
                            v-model="mail.message"
                            />
                            <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                            </b-form-group>
                    </validation-provider>
                </b-col>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationMailForm"
                  >
                    Submit
                  </b-button>
                </b-col>
            </b-row>
                
            </b-form>
            </validation-observer>
            <div class="">
                <b-table
                            striped
                            hover
                            responsive
                            :per-page="perPage"
                            :current-page="currentpsPage"
                            :items="properties_selected"
                            :fields="fields_propertiesmail"
                            show-empty
                            empty-text="No matching records found"
                            
                            
                            
                        >
                        <template #head(id)>
                            <b-form-checkbox  title="Toggle Select All" @input="selectAll" v-model="selectAlled" />
                            </template>
                       
                        <template #cell(id)="data">
                            <input type="checkbox"  v-model="pmails" :value="data.item.id" />
                           
                        </template>
                        <template #cell(units)="data">
                            {{ data.item.units }} 
                            {{ data.item.address_no }}
                        </template>
                        <template #cell(close_deal)="data">
                        <b-button variant="flat-primary" class="pb0" :to="{ name: 'close-deal-create', params: { id: id, property_id:data.item.id } }">
                            close
                        </b-button>
                         
                        </template>
                        <template #cell(property)="data">
                            <div class="list-property">
                           <router-link :to="{ name: 'property-edit', params: { id: data.item.id } }" >{{ data.item.property_code }}</router-link>
                            <b-badge v-if="data.item.action_id == 1" variant="light-secondary">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 2" variant="light-info">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 3" variant="light-success">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.action_id == 4" variant="light-warning">
                                {{ data.item.action_name }}
                            </b-badge>
                            <b-badge v-if="data.item.status_id == 1" variant="light-success">
                                {{ data.item.status_code }}
                            </b-badge>
                            <b-badge v-else variant="light-secondary">
                                {{ data.item.status_code }}
                            </b-badge>

                            <br />
                            {{ data.item.place_name }}<br /><br />
                          
                            </div>
                            
                            
                        </template>
                        <template #cell(image)="data">
                                <b-button variant="flat-primary" class="pb0" :to="{ name: 'property-upload', params: { id: data.item.id } }">
                                <b-img   class="img_property" v-if="data.item.property_images[0] "
                                            :src="data.item.property_images[0].fullfilepath"
                                            
                                        />
                                <b-img   class="img_property" v-else
                                            :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                                        
                                        />
                                </b-button>
                                
                            </template>
                        
                            <template #cell(owner)="data">
                                
                                {{ data.item.owner_firstname }} {{ data.item.owner_lastname }} 
                        
                            </template>
                            <template #cell(price_rent)="data">
                                {{ data.item.rent_price | getcomma }}
                            
                            </template>
                            <template #cell(price_sale)="data">
                                {{ data.item.sale_price | getcomma }}
                                
                            </template>
                        </b-table>
                        

                        
                            <b-pagination
                                    v-if="totalpsRows > 0"
                                    v-model="currentpsPage"
                                    :total-rows="totalpsRows"
                                    :per-page="perPage"
                                    align="center"
                                    size="sm"
                                    class="my-0"
                                />

            </div>
            <div ref="templateMail">
            <table style="background-color: #bfda53;margin: 0 auto;max-width: 680px;border-spacing: 0px;" >
                <tr>
                <th style="text-align:left;padding: 10px;">
                    <img style="max-width:200px" src="http://128.199.95.64:100/test/public/styles/img/logo-white.png">
                </th>
            </tr>
            <tr style="background: #fff;">
                <td style="padding: 20px;">
                    <div style="text-align:right;font-weight:500;font-size:14px;">
                        <b>ENQUIRY ID</b> : #{{ input.code }}<br/>
                         {{  timestamp }}
                    </div>
                    <p style="font-size:14px;" v-html="mail.message"></p>
                        <div v-for="(property, index) of properties_mail"   :key="index"  style="float: left;width: 50%;min-width: 300px;display: inline-block;height:450px;">
                             
                                 <div style="padding: 0 15px;">
                                    
                                      <a target="_blank" :href="'https://findbangkokroom.com/condo/'+property.place_name.replace(/\s/g,'-')+'/'+property.property_code"> <b-img   class="img_property" v-if="property.property_images[0]"
                                            :src="property.property_images[0].fullfilepath"
                                             style="width:100%; object-fit:cover; height:170px;"
                                            
                                        />
                                         <b-img   class="img_property" v-else
                                            :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                                            style="width:100%; object-fit:cover; height:170px;"
                                            
                                        /></a>
                                        <h3 style="margin: 5px 0;font-size: 16px;color:#333;"><a target="_blank" :href="'https://findbangkokroom.com/condo/'+property.place_name.replace(/\s/g,'-')+'/'+property.property_code"> <b>{{ property.place_name }}</b></a> <span style="font-size: 14px;font-weight: 400;">({{property.property_code}})</span></h3>
                                      
                                         <p style="margin:5px 0; font-size: 14px;line-height: 18px;">  
                                             Zone : {{property.zone_name}}<br/>
                                             <span v-if="property.bts_name"><b>BTS</b> {{ property.bts_name}} {{property.bts_distance}} M.<br/></span>
                                             <span v-if="property.mrt_name"><b>MRT</b> {{  property.mrt_name }} <br/></span>
                                            <span v-if="property.airport_name" >Airport Link : {{ property.airport_name }} {{ property.airportlink_distance}} M.<br/></span>
                                            {{property.bedroom }} Bedroom 
                                            {{property.sqm }} sqm. <br/>
                                            {{ property.floors }} Fl <br/>
                                            {{property.furniture_name }}<br/>
                                            <b v-if="property.price_sale != 0" style="font-size: 15px;line-height: 24px;">Sale Price : {{property.sale_price | getcomma}} THB</b><br/> 
                                            <b v-if="property.price_rent != 0" style="font-size: 15px;line-height: 24px;">Rent Price : {{property.rent_price | getcomma }} THB</b>
                                         </p>
                                 </div>
                                
                                 
                         
                        </div>                                                                                   
                   </td>
            </tr>
            <tr style="background-color:#bfda53;">
                   <td>
                        <div style="font-size:14px; padding:20px; color:#fff;">
                            <a href="findbangkokroom.com" style="color:#fff;">www.findbangkokroom.com</a><br />
                            888 polaris sukhumvit rd. soi 20<br />
                            Klongtoey , Klongtoey Bangkok 10110<br />
                            tel : <a href="tel:0990955535" style="color:#fff;">099-095-5535</a><br />
                            Line : @findbangkokroom<br />
                            whatsapp : 099-095-5535<br />
                            <a href="to:mail@findbangkokroom.com" style="color:#fff;">mail@findbangkokroom.com</a>
                          

                        </div>                    
                   </td>
            </tr>
            </table>
            </div>
            </div>
         </template>      
    </b-sidebar>
</div>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup,BFormSpinbutton,BTable,BPagination,BFormInvalidFeedback
} from 'bootstrap-vue'

import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink,BBadge, BSidebar, VBToggle
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { isDynamicRouteActive } from '@core/utils/utils'
import EnquiryMail from './EnquiryMail.vue'
import router from '@/router'
import draggable from 'vuedraggable'

import FormComment from './FormComment.vue'

/* eslint-disable global-require */

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import emailjs from 'emailjs-com';
import { useClipboard } from '@vueuse/core'

export default {
    components: {
        BCardActions,
        VueAutosuggest,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormTextarea,
        BFormRating,
        BFormSpinbutton,
        BFormInvalidFeedback,
        vSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
        BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink,
        BTable,BPagination,
        BBadge,
        EnquiryMail,
        BSidebar,
        quillEditor,
        draggable,
        FormComment
        
       
  },
  data(){
      return{
          id:router.currentRoute.params.id,
          selectAlled:false,
          userData: JSON.parse(localStorage.getItem('userData')),
          unit:null,
          floor:null,
          types:null,
          building:null,
          bedroom:null,
          sqm:null,
          price_rent:null,
          price_sale:null,
            price_min:null,
          price_max:null,
          action_id:null,
          mail:{
            subject:'Property Listing for you',
            mailto:null,
            mailcc:null,
            message:null
          },
           options: {
        handle: '.handle'
      },
      statusId:'',
          pmails:[],
          isSidebarMailOpen:false,
          timestamp:null,
          input:{
              title:null,
              description:null,
              looking:null,
              fors:null,
              enquiry_source:null,
              bedroom:0,
              bathroom:1,
              buy_min:null,
              buy_max:null,
              rent_min:null,
              rent_max:null,
              zone_id:null,
              bts_id:null,
              mrt_id:null,
              airport_id:null,
              place_id:null
          },
          place_id:'',
          zone:[],
          bts:[],
          mrt:[],
          airport:[],
          properties:[],
          
          users:[],
          users_selected:[],
          properties_selected:[],
         properties_mail:[],
          filter_user:null,
          option_looking: 
            [
            { id: "Sale & Rent", title: 'Sale & Rent' }, 
            { id: "Sale", title: 'Sale' }, 
            { id: "Rent", title: 'Rent' }, 
          
         ],
         option_fors: 
        [
            { id: "Own Stay", title: 'Own Stay' }, 
            { id: "Investment", title: 'Investment' }, 
           
          
         ],
         option_sources:[
            { id: "Walk-in", title: 'Walk-in' }, 
            { id: "Call in", title: 'Call in' }, 
            { id: "At Site", title: 'At Site' }, 
            { id: "Website", title: 'Website' }, 
            { id: "Our Website", title: 'Our Website' }, 
            { id: "Refer", title: 'Refer' }, 
            { id: "Other", title: 'Other' }, 

         ],
         inputProps: {
            id: 'autosuggest__input_ajax',
            placeholder: 'Property Pin',
            class: 'form-control',
            name: 'pin_id',
        },
        suggestions: [],
        sectionConfigs: {
            place: {
                limit: 6,
                label: 'Place',
                onSelected: selected => {
                    this.input.place_id = selected.item.id;
                        this.properties = [];
                    this.currentPage = 1
                   this.searchQuery = selected.item.name;
                    this.get_properties();
                    
                    
                },
            },
        },
        searchQuery:'',
        propertyfilter:null,
            ownerfilter:null,
        perPage: 5,
        perPagep: 15,
        offset:0,
        pageOptions: [3, 5, 10],
        totalRows: 0,
        totalRowsSelected: 1,
        currentPage: 1,
        currentpsPage:1,
        currentpPage:1,
        currentPageSelected: 1,
        currentpPageSelected:1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        totalpRows:0,
         totalpsRows:0,
          bts_id:'',
        zone_id:'',
        mrt_id:'',
        airport_id:'',
        ps :[],
         fields_propertiesmail:[
            {
                key: 'id', label: '', tdClass:"text-center",
            },
           
            {
                key: 'image', label: 'Image', tdClass:'width-300 p-1',
            },
            {
                key: 'property', label: 'Property Code',
            },
             {
                key: 'owner', label: 'Owner',
            },
            {
                key: 'units', label: 'units',
            },
            {
                key: 'floors', label: 'Floor',
            },
            {
                key: 'type_name', label: 'Type',
            },
            {
                key: 'buildiing', label: 'Building',
            },
            {
                key: 'bedroom', label: 'Bd',
            },
            {
                key: 'sqm', label: 'Sqm',
            },
            {
                key: 'price_rent', label: 'Rent',
            },
            {
                key: 'price_sale', label: 'Sale',
            },
            {
                key: 'exp', label: 'Exp/Avail Date  ',
            },
            {
                key: 'rating', label: 'Rating',
            },
            {
                key: 'furniture_name', label: 'Furniture',
            },
            {
                key: 'bts_name', label: 'BTS',
            },
            {
                key: 'mrt_name', label: 'MRT',
            },
            {
                key: 'zone_name', label: 'Zone',
            },
             {
                key: 'id', label: 'ID',
            },
            
            
        ],
        fields_propertiesselected:[
            {
                key: 'id', label: '',
            },
             {
                key: 'handle', label: '',
            },
          
            {
                key: 'image', label: 'Image', tdClass:'width-300 p-1',
            },
            {
                key: 'property', label: 'Property Code',
            },
             {
                key: 'owner', label: 'Owner',
            },
            {
                key: 'units', label: 'units',
            },
            {
                key: 'floors', label: 'Floor',
            },
            {
                key: 'type_name', label: 'Type',
            },
            {
                key: 'close_deal', label: 'Close Deal',
            },
            {
                key: 'buildiing', label: 'Building',
            },
            {
                key: 'bedroom', label: 'Bd',
            },
            {
                key: 'sqm', label: 'Sqm',
            },
            {
                key: 'price_rent', label: 'Rent',
            },
            {
                key: 'price_sale', label: 'Sale',
            },
            {
                key: 'exp', label: 'Exp/Avail Date  ',
            },
            {
                key: 'rating', label: 'Rating',
            },
            {
                key: 'furniture_name', label: 'Furniture',
            },
            {
                key: 'bts_name', label: 'BTS',
            },
            {
                key: 'mrt_name', label: 'MRT',
            },
            {
                key: 'zone_name', label: 'Zone',
            },
             {
                key: 'id', label: 'ID',
            },
            
            
        ],
        fields_properties:[
            {
                key: 'id', label: '',
            },
           
            {
                key: 'image', label: 'Image', tdClass:'width-300 p-0',
            },
            {
                key: 'property', label: 'Property Code',
            },
             {
                key: 'owner', label: 'Owner',
            },
            {
                key: 'units', label: 'units',
            },
            {
                key: 'floors', label: 'Floor',
            },
            {
                key: 'type_name', label: 'Type',
            },
            {
                key: 'buildiing', label: 'Building',
            },
            {
                key: 'bedroom', label: 'Bd',
            },
            {
                key: 'sqm', label: 'Sqm',
            },
            {
                key: 'price_rent', label: 'Rent',
            },
            {
                key: 'price_sale', label: 'Sale',
            },
            {
                key: 'exp', label: 'Exp/Avail Date  ',
            },
            {
                key: 'rating', label: 'Rating',
            },
            {
                key: 'furniture_name', label: 'Furniture',
            },
            {
                key: 'bts_name', label: 'BTS',
            },
            {
                key: 'mrt_name', label: 'MRT',
            },
            {
                key: 'zone_name', label: 'Zone',
            },
             {
                key: 'id', label: 'ID',
            },
            
            
        ],
        fields: [
            {
                key: 'id', label: '',
            },
            {
                key: 'name', label: 'ชื่อ-นามสกุล',
            },
             {
                key: 'email', label: 'อีเมล',
            },
             {
                key: 'phone', label: 'เบอร์โทรศัพท์',
            },
       
      
        ],
         
      }
  },
  directives: {
   
     'b-toggle': VBToggle,
      Ripple,
  },
  created(){
     this.get_properties();
      this.getZone();
      this.getBts();
      this.getMrt();
      this.getAirport();
     setTimeout(() => {
          this.getData();
     }, 500);
     this.getNow();
       
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch:{
   /* 'input.bedroom':function(value){
    
         this.properties = [];
         this.currentPage = 1
        this.bedroom=value;
        this.get_properties();

    },
    'input.bts_id':function(value){
        console.log('bts_id', value);
         this.properties = [];
         this.currentPage = 1
         this.bts_id= '';
         if(value){
              this.bts_id=value.id;
         }
       
        this.get_properties();

    },
    'input.zone_id':function(value){
        console.log('bedroom', value);
         this.properties = [];
         this.currentPage = 1
         this.zone_id= '';
         if(value){
              this.zone_id=value.id;
         }
        
        this.get_properties();

    },
     'input.airport_id':function(value){
        console.log('bedroom', value);
         this.properties = [];
         this.currentPage = 1
         this.airport_id= '';
         if(value){
              this.airport_id=value.id;
         }
        
        this.get_properties();

    },
    'input.mrt_id':function(value){
        console.log('bedroom', value);
         this.properties = [];
         this.currentPage = 1
          this.mrt_id= '';
         if(value){
              this.mrt_id=value.id;
         }
        
        this.get_properties();

    },
    'input.bathroom':function(value){
        console.log('bedroom', value);
         this.properties = [];
         this.currentPage = 1
        this.bathroom=value;
        this.get_properties();

    },*/
    /* 'input.rent_min':function(value){
        this.price_min = value;
        if(!this.input.rent_min && !this.input.rent_max){
             this.action_id = '';
        }else{
             this.action_id = '2,4';
        }
         this.currentPage = 1
         this.get_properties();
        

    },
    'input.rent_max':function(value){
        this.price_max = value;
      if(!this.input.rent_min && !this.input.rent_max){
             this.action_id = '';
        }else{
             this.action_id = '2,4';
        }
         this.currentPage = 1
         this.get_properties();
        

    },
    'input.buy_min':function(value){
        this.price_min = value;
         if(!this.input.buy_min && !this.input.buy_max){
             this.action_id = '';
        }else{
             this.action_id = '1,3';
        }
         this.currentPage = 1
         this.get_properties();
        

    },
    'input.buy_max':function(value){
        this.price_max = value;
        if(!this.input.buy_min && !this.input.buy_max){
             this.action_id = '';
        }else{
             this.action_id = '1,3';
        }
         this.currentPage = 1
         this.get_properties();
        

    },*/
    pmails(value){
        this.pmails = value;
        if(value.length == 0){
            this.selectAlled = false;
        }
       
        this.add_tomail();
    },
    floor(value){
        console.log('floor',value);
        this.floor = value;
         this.currentPage = 1
          this.get_properties();

    },
    building(value){
         this.building = value;
         this.currentPage = 1
          this.get_properties();

    },
      propertyfilter(value){
        this.properties = [];
         this.currentPage = 1
         this.propertyfilter = value;
        this.get_properties();
      },
      ownerfilter(value){
         this.properties = [];
         this.currentPage = 1
         this.ownerfilter = value;
        this.get_properties();
      },
      bedroom(value){
        this.properties = [];
         this.currentPage = 1
         this.bedroom = value;
        this.get_properties();
      },
      
      price_rent(value){
         this.properties = [];
         this.currentPage = 1
         this.price_rent = value;
        this.get_properties();
      },
      price_sale(value){
         this.properties = [];
         this.currentPage = 1
         this.price_sale = value;
        this.get_properties();
      },
      unit(value){
        this.properties = [];
         this.currentPage = 1
         this.unit = value;
        this.get_properties();
      },
      sqm(value){
        this.properties = [];
         this.currentPage = 1
         this.sqm = value;
        this.get_properties();
      },
      filter_user(value){
           this.users = [];
          this.filter_user = value;
          this.currentPage = 1
          this.get_users();
      },
      currentPage(value){
          this.users = [];
        this.currentPage = value;
         this.get_users();
      },
      
      currentpPage(value){
           this.properties = [];
        this.currentpPage = value;
         this.get_properties();
      }

  },
  filters: {
    getcomma: function(text){
      if(!text){
        return ''

      }
			 var nStr = text;
			nStr += '';
			var x = nStr.split('.');
			var x1 = x[0];
			var x2 = x.length > 1 ? '.' + x[1] : '';
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}
			return x1 ;
		},
  },
  methods: {
      selectAll() {
          
            this.pmails = [];
            console.log('mla',this.pmails.length);
            if (this.selectAlled) {
               // console.log('ml',this.properties_selected);
               
               this.properties_selected.forEach(ps => {
                   this.pmails.push(ps.id);

               })
                    
                   
            }
            this.add_tomail();
        },
        change_status(){
        this.properties = []
         this.currentPage = 1
        this.get_properties();

    },
      formmail_show(){
   
        this.isSidebarMailOpen = true;
      },
       checkMove: function(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

      add_tomail(){
          //properties_mail
          let selects = this.properties_selected;
          let pmails = this.pmails;
          console.log('pmail',selects);
          this.properties_mail  = selects;
        this.properties_mail  = pmails.map(function(value) {
             let index =  selects.findIndex(p => p.id === value);
            console.log('index',index);
            if(index > -1){  
                  return selects[index];
            }
              
        
            });

         // console.log('pmails',this.pmails);

      },
      formmail_close(){
        this.isSidebarMailOpen = false;
      },
      getNow: function() {
            const today = new Date();
            const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date +' '+ time;
            this.timestamp = dateTime;
        },
      async getData(){
         // console.log('userdata',this.userData);
           this.mail.mailcc = this.userData.email;
           var enquiry = await axios.get('enquiry/v1/get/'+this.id);
           var enq = enquiry.data.data;
           console.log('enq',enq);
           this.input = enq;
           this.place_id = this.input.place_name;
           if(this.input.place_name){
               this.searchQuery = this.input.place_name;
                this.properties = [];
                this.currentPage = 1
              
                this.get_properties();

           }
           this.mail.mailto = enq.email;
          
        this.mail.message = '<div>Dear ' + enq.firstname + ' ' + enq.lastname + ',</div>' +
                '<div>Thank you for your enquiry , We found properties that you might interested below detail.</div>' +
                '<div>Please &nbsp;give 1-2 days in advance for appointment</div>';                           
          
           if(enq.bts_id){
                var bts = this.bts.filter(j => j.id == enq.bts_id);
                if(bts){
                  this.input.bts_id =bts[0];
                }
           }
           if(enq.mrt_id){
                var mrt = this.mrt.filter(j => j.id == enq.mrt_id);
                if(mrt){
                  this.input.mrt_id =mrt[0];
                }
           }
           if(enq.airport_id){
                var airport = this.airport.filter(j => j.id == enq.airport_id);
                if(airport){
                  this.input.airport_id =airport[0];
                }
           }
           if(enq.zone_id){
                var zone = this.zone.filter(j => j.id == enq.zone_id);
                if(zone){
                  this.input.zone_id =zone[0];
                }
           }
           if(enq.properties){
               const props = this.properties;
               let selected = [];
               enq.properties.forEach(item => {
                   selected.push(item.property_id*1);
                    
          
               });
               if(selected){
                   this.ps = selected;
                   this.get_propertyselect();
               }
               
              
               /* enq.properties.forEach(item => {
                    
                    let index =  this.properties.findIndex(p => p.id === item.property_id);
                    console.log('index',index);
                    if(index > -1){  
                         this.properties_selected.push(this.get_properties[index]);
                         this.totalpsRows = this.properties_selected.length;
                    }
                    
                    return false
                })*/

           }
           //console.log('enquiry',enquiry);
          ///enquiry/v1/get/
      },
      add_user(item){
          let index =  this.users_selected.findIndex(p => p.id === item.id);
          if(index <  0){
            this.users_selected.push(item);
            this.totalRowsSelected = this.users_selected.length;
          }
          
        

      },
      async add_property(item){
          let index =  this.properties_selected.findIndex(p => p.id === item.id);
          if(index <  0){
            //
            let inp = {}
            inp.id = this.id
            inp.property_id =item.id;
            const inps = JSON.stringify(inp);
            let result = await axios.post('enquiry/v1/addEnquiryprop', inps);
            this.properties_selected.push(item);
            this.totalpsRows = this.properties_selected.length;
          }

      },
      remove_user(id){
          
        this.users_selected = this.users_selected.filter(v => v.id  != id);
        /* this.users_selected= $.grep(this.users_selected, function(e){ 
                return e.id != id; 
            });*/
          this.totalRowsSelected = this.users_selected.length;
          
       

      },
      async remove_property(id){
          this.properties_selected = this.properties_selected.filter(v => v.id  != id);
          
            let result = await axios.delete('enquiry/v1/removeEnquiryprop/'+this.id+'/'+id);
       
          this.totalpsRows = this.properties_selected.length;
      },
      async get_propertyselect(){
           let data = {
            perpage: 40,
            offset:0,
       
            bedroom:this.bedroom,
            unit:this.unit,
            sqm:this.sqm,
            statusId:null,
            price_sale:null,
            price_rent:null,
           enq_id:this.id
         }
         
         data = JSON.stringify(data);
        let pf = await axios.post('cache-master/v1/property-filter', data);
        console.log('data_selected',pf);
        if(pf.data){
             this.properties_selected = pf.data.data;
          
             this.totalpsRows = pf.data.total;
         }else{
             this.totalpsRows = 0;
         }
          console.log('properties_selected',this.properties_selected);
           // this.properties_selected = selected;
               //  this.totalpsRows = this.properties_selected.length;

      },
      async get_properties(){
          const offset =  this.perPagep*(this.currentpPage-1);
        let data = {
            perpage: this.perPagep,
            offset:offset,
       
            bedroom:this.bedroom,
            unit:this.unit,
            sqm:this.sqm,
            statusId:this.statusId==="1"?this.statusId:null,
            price_sale:null,
            price_rent:null,
             bts_id:this.bts_id,
            mrt_id:this.mrt_id,
            zone_id:this.zone_id,
            airport_id:this.airport_id,
             propertyfilter:this.propertyfilter,
            ownerfilter:this.ownerfilter,
            keyword:this.searchQuery,
            price_min:this.price_min,
            price_max:this.price_max,
            action_id:this.action_id,
            floors:this.floor,
            building:this.building
         }
         data = JSON.stringify(data);
        let properties = await axios.post('cache-master/v1/property-filter', data);
        if(properties.data){
             this.properties = properties.data.data;
          
             this.totalpRows = properties.data.total;
         }else{
             this.totalpRows = 0;
         }
        console.log('properties',properties);

      },
      async get_users(){
         
         const offset =  this.perPage*(this.currentPage-1);
         console.log('filter',this.filter_user );
         let data = {
            perpage: this.perPage,
            offset:offset,
            keyword:this.filter_user
            
            
         }
         data = JSON.stringify(data);
         var users = await axios.post('/users/v1/lists/3', data);
         if(users.data){
             this.users = users.data.data;
            console.log('users',this.users);
             this.totalRows = users.data.total;
         }else{
             this.totalRows = 0;
         }
         
        

      },
      getAirport(){
          axios.get('transit/v1/listtype/airport')
            .then(res => { 
            // console.log('res',res);
                this.airport = res.data.data; 
            })
      },
      getMrt(){
          axios.get('transit/v1/listtype/mrt')
            .then(res => { 
            // console.log('res',res);
                this.mrt = res.data.data; 
            })
      },
      getBts(){
          axios.get('transit/v1/listtype/bts')
            .then(res => { 
            // console.log('res',res);
                this.bts = res.data.data; 
            })
      },
      getZone(){
         let queryParams1 = {
            perpage: 1000,
            offset:0
        
        }
        let data1 = JSON.stringify(queryParams1);
         
        axios.post('zone/v1/lists',data1)
        .then(res => { 
            
            this.zone = res.data.data; 
        })
      },
     
        fetchResults() {
        const { place_id } = this
        console.log('query',place_id);
        if(place_id){

        
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                var data = JSON.stringify({
                    "keyword": place_id
                    });
                const placePromise = axios.post('autocomplete/v1/place',data)
            
                

                Promise.all([placePromise]).then(values => {
                    this.suggestions = []
                this.selected = null
                    console.log('values', values[0].data.data);
                    var data =  values[0].data.data;
                    this.suggestions.push({ name: 'place', data: data })
                
                // this.suggestions.push();
                }).catch(error=>{
                    console.log('error', error)
                })
            }, this.debounceMilliseconds)
        }else{
            this.suggestions = []
        }
        },
        
        filterResults(data, text, field) {
        return data.filter(item => {
            if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field]
            }
            return false
        }).sort()
        },
        renderSuggestion(suggestion) {
            console.log('suggestion',suggestion);
        
        return suggestion.item.name
        },
        getSuggestionValue(suggestion) {
        const { name, item } = suggestion
        console.log('suggestion',suggestion);
        
        return name === 'hotels' ? item.title : item.name
        },
          doCopy(Pname,Pcode) {
      this.$copyText('https://findbangkokroom.com/condo/'+Pname.replace(/\s/g,'-')+'/'+Pcode).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
    makeToast(variant = null,title,message) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      })
    },
    async sendMail(){
        let message =  this.$refs.templateMail.innerHTML;
        
      /*  var templateParams = {
            subject: this.mail.subject,
            name: 'James',
            notes: 'Check this out!',
            message: message,
            code: this.input.code,
            properties:this.properties_selected,
            email:this.mail.mailto
        };*/
        let params = {
            to:this.mail.mailto,
            cc:this.mail.mailcc,
            //from:this.userData.name+' <'+this.userData.email+'>',
            from:'"'+this.userData.name+'" <'+this.userData.email+'>',
           // replyTo:this.mail.mailcc,
            subject:this.mail.subject,
            message: message,
        }
       // console.log('templateParams',templateParams);

      try {
      /* let status = await emailjs.send('service_ko4kafc', 'template_yi1voc8', templateParams,
        'user_Y1lQ1v5Hbn27P4BQhuayo', {
          name: 'FBR',
          email: 'deoz@windowslive.com',
          message:'texst'
        })*/
        var data = JSON.stringify(params);
        let response = await axios.post('fbr-mail/v1/sendcontactus',data);
        if(response.data.success){
           this.makeToast('sucess','Send Mail',"done.");
            
        }else{
              this.makeToast('danger','Error 403',"Please, sen mail again.");

        }
        this.formmail_close();
        
       
      } catch(error) {
          console.log({error})
      }

    },
     validationMailForm(event) {
          this.$refs.refFormObserver.validate().then(success => {
            if(success){
                this.sendMail();

               
               
            }
          });
     },
     
      validationForm(event) {
          /*this.$refs.refFormObserver.validate().then(success => {
            if(success){
                this.sendMail();

               
               
            }
          });*/
         
          this.$refs.simpleRules.validate().then(success => {
               if (success) {
                    
                   if(this.properties_selected.length == 0){
                           this.makeToast('danger','Error 403',"Please, select room.");
                   }else{
                       this.input.properties = this.properties_selected;
                       this.input.created_by = this.userData.id;
                       
                       //this.input.users = this.users_selected;
                       var data = JSON.stringify(this.input);
                      //console.log('data',data);
                       
                           axios.put('enquiry/v1/update/'+this.id,data)
                            .then(res => { 
                                console.log('res',res);
                                if(!res.data.success){
                                    this.makeToast('danger','Error 403',res.data.message);
                                
                                
                                }else{
                                this.$router.push('/enquiry/lists', () => {
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                        title: `Done`,
                                        icon: 'CoffeeIcon',
                                        variant: 'success',
                                        text: `You have successfully created.`,
                                        },
                                    })
                                });
                                

                                }
                            // this.actions = res.data.data; 
                            }).catch(error => {
                                this.makeToast('danger','Error 403',error);
                                console.log('error',error);
                            })

                       
                   }
                   
               }
          });
      }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/ui-feather.scss';
</style>

<template>
     <b-card title="Comment">
       <b-button @click="formcomment_show" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
         Add Comment
       </b-button>
       
      <app-timeline v-if="comments" class="mt-3">

        <!-- 12 INVOICES HAVE BEEN PAID -->
        <template v-for="(comment,key) in comments" >
        <app-timeline-item variant="info" :key="'comments-'+key"  v-if="key==0" >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ comment.title }}</h6>
            <small class="text-muted">{{ comment.created_at | formatDate }}</small>
          </div>
        
          <b-media>
            <template #aside v-if="comment.fullfilepath">
              <b-avatar :src="comment.fullfilepath"  />
            </template>
            <template #aside v-else>
              <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
            </template>
           
            <h6>{{ comment.created_name }} <b-badge variant="danger" style="cursor:pointer;" v-if="userData.id==comment.created_by" @click="deleteComment(comment.id)">Delete</b-badge></h6>
            <p class="mb-0" v-html="comment.comment"></p>
            
          </b-media>
        </app-timeline-item>
        </template>
      </app-timeline>
       <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        v-model="isSidebarcommentOpen"
        no-header
        right
        backdrop
        shadow
        ref="formOwner"
       class="side-bar-responsive"
        >
         <template >
             <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          
                <h5
                  
                  class="mb-0"
                >
                 Comment
                </h5>
                <div>
                
                  <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="formcomment_close"
                  />
                </div>
                
          </div>
          
          <messages :id="id" v-if="isSidebarcommentOpen" v-on:childToClose="onSubmitClose"  />
        
           
         </template>      
       </b-sidebar>
     <b-sidebar
        id="log-right"
        bg-variant="white"
        v-model="isSidebarlogOpen"
        no-header
        right
        backdrop
        shadow
        ref="logs"
        class="side-bar-responsive"
        >
        <template>
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
             <h5
                  
                  class="mb-0"
                >
                 Logs
                </h5>
                <div>
                
                  <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="formlog_close"
                  />
                </div>
                
          </div>
           <logs :id="id" v-if="isSidebarlogOpen"   />  
        </template>
     </b-sidebar>
     
  </b-card>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormSpinbutton,
  BFormCheckbox, BCard, BImg, BMedia, BAvatar,VBToggle,BSidebar,BBadge
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BFormTextarea, BCardText } from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import moment from 'moment'
import Messages from '../../components/comments/Messages.vue'
import Logs from '../../components/logs/Logs.vue'
export default {
components: {
    BCardActions,
    BBadge,
    BCardText,
    BFormTextarea,
    VueAutosuggest,
     ValidationProvider,
        ValidationObserver,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    VuePerfectScrollbar,
    filterTags,
    formatDateToMonthShort,
    AppTimeline,
    AppTimelineItem, BCard, BImg, BMedia, BAvatar,BSidebar,
    Messages,
    Logs,
    BButton
  },
    data() {
       return {
          perfectScrollbarSettings:{
            maxScrollbarLength: 150,
          },
           userData: JSON.parse(localStorage.getItem('userData')),
          input_comment:{
            title:'Comment',
            comment:null,
            created_by:null,
            eq_id:this.id,

          },
          comments:[],
          logs:[],
          comment:null,
           isSidebarcommentOpen:false,
           isSidebarlogOpen:false
       } 
    },
     props: {
   
      id:{
        type: [String, Number]
      }
    },
    directives: {
   
     'b-toggle': VBToggle,
      Ripple,
  },
  filters: {
    formatDate: function(value){
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
      }else{
        return '';

      }

    }
  },
  created(){
    this.getComments();
   
  },
    methods: {
      formlog_show(){
         this.isSidebarlogOpen = true;
      },
      formlog_close(){
        this.isSidebarlogOpen = false;
      },
      formcomment_show(){
        this.isSidebarcommentOpen = true;
      },
      formcomment_close(){
        this.isSidebarcommentOpen = false;
      },
      onSubmitClose(){  
        this.isSidebarcommentOpen = false;
        this.getComments();
      },
       makeToast(variant = null,title,message) {
        this.$bvToast.toast(message, {
            title: title,
            variant,
            solid: true,
        })
      },
    async deleteComment(id){
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Comment', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value === true){
             axios.delete('enquiry/v1/delteComment/'+id).then(res=>{
                this.makeToast('success','Delete Comment','Completed');
              this.getComments();
             });
            
            //this.deleteData(id)
          }
          console.log('value',value);
          //this.deleteData(id)
        })
      
   
    },
   
    async getComments(){
      this.comments = [];
       let data = {
            types:'comment',
            perPage:1,
            Offsets:0
        }
        var json = JSON.stringify(data);
        let comments = await  axios.post('enquiry/v1/getComments/'+this.id,json);
        console.log('comments',comments);
  
      if(comments.data.success){
        this.comments = comments.data.data;

      }
      console.log('comments',comments);

    },
   
      

    }
}
</script>